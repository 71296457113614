.editor {
    flex-grow: 1;
    display: flex;

    &__code {
        flex: 5 0 80%;
    }

    &__nothing {
        flex: 1 1 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2em;
        color: #0008;
        background: #0001;
    }

    &__sidebar {
        max-width: 30%;
        overflow: auto;
        height: 0;
        min-height: 100%;
        flex: 1 1 20%;
        background-color: #f0f0f0;
        padding: 1em;

        &__title {
            font-weight: bold;
        }
    }
}

.ruleset-info {
    &__label {
        margin-top: 0.6em;
        margin-bottom: 0.25em;

        font-weight: bold;
        display: block;
    }

    &__data {
        resize: vertical;
        display: block;
        width: 100%;
    }
}

.inspection-test-list {
    margin-top: 1em;
    list-style: none;
    overflow: auto;
}

.inspection-test {
    border-bottom: 1px solid #0005;
    cursor: pointer;

    &--selected {
        background-color: #0001;
        border-top: 3px solid #556;
        border-bottom: 3px solid #556;
    }

    &:hover {
        background-color: #0001;
    }

    &__header {
        padding: 0.4em 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        &__ref-index {
            font-size: 0.8em;
            opacity: 0.5;
            padding-right: 0.5em;
        }

        &__description {
            font-size: 0.9em;
            font-weight: 500;
            flex-grow: 1;
            padding-right: 0.3em;
            color: #445;
        }

        &__description-ref {
            opacity: 0.6;
            font-size: 0.8em;
            padding-right: 0.3em;
        }

        &__description-ref-index {
            &::before {
                content: "/ ";
                color: #556;
            }
            padding-right: 0.3em;
            opacity: 0.4;
            font-size: 0.7em;
        }
    }

    &__edit {
        background: #0001;
        padding: 0.5em;
        &__input {
            margin-top: 0.2em;
            display: block;
            width: 100%;
        }
    }
}
