*   {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Work Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    height: 100vh;
    width: 100vw;
}

.button {
    cursor: pointer;
    appearance: none;
    padding: 0.2em 0.5em;
    background: #556;
    color: white;
    font-size: 0.9em;
    border: none;
    font-weight: bold;
    
    &:hover {
        background: #445;
    }

    &--block {
        display: block;
        width: 100%;
    }
    &--margin {
        margin-top: 0.3em;
        margin-bottom: 0.3em;
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: all;
    background-color: #000d;
    z-index: 1000;
    color: white;

    &--center-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.mb {
    margin-bottom: 0.5em;
}

.mt {
    margin-top: 0.5em;
}