
.app {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &__nav {
        background-color: #556;   
        color: white;
        font-size: 1.2rem;
        font-weight: bold;
        padding: 0.5em;

        &__title {
            margin-right: 1em;
            border-bottom: 3px solid #889;
        }
    }

    
}

